import React from 'react'
import '../assets/css/Footer.styl'
function Footer() {
        return (
            <div className="footer fc-hui7 flex-column flex-items-center flex-justify-center">
               <div className="fs-16 mt-16">© 广西爱尚娱网络科技有限公司 版权所有</div>
               <div className="fs-16 mt-16"><a className="mt-17" href="https://beian.miit.gov.cn/#/Integrated/recordQuery" target="_blank">桂ICP备2021007602号-3</a></div>
               <div className="fs-16 mt-16">
                <a target="_blank" href="https://beian.mps.gov.cn/#/query/webSearch?code=45010002450510" className="wanganbeian-2"><img src={require("../assets/images/wanganbeian.png")} className="wanganbeian-3"/><p className="wanganbeian-4">桂公网安备45010002450510号</p></a>
                </div>
		 	    
            </div>
        );
    }
export default Footer;