import React from 'react'
import '../assets/css/Product.styl'
function Product() {
        return (
            <div className="product">


                <div className="flex-justify-center box">
                    <div>
                        <img src={require("../assets/images/phone_5.png")} alt="" width="324" height="514"/>
                    </div>
                    <div className="flex-column content-right  flex-justify-center">
                        <img src={require("../assets/images/logo5.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        爱尚号角追书（快应用）
                        </div>
                        <div className="fs-20 mt-24 bold-200 ">
                        超多热门小说免费读，特别简洁的设计让您除了阅读无需关注其他。
                        </div>
                        
                        <a href="ashjzs_q/index.html">
                            <div className=" detail-btn">
                                查看更多截图
                            </div>
                        </a>
                        <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/ashjzs_q/app.rpk">
                            <div className="download-btn">
                               下载应用
                            </div>
                        </a>
                    </div>
                </div>

                <div className="flex-justify-center box">
                    <div className="flex-column content  flex-justify-center">
                        <img src={require("../assets/images/logo4.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                            文读小说（快应用）
                        </div>
                        <div className="fs-20 mt-24 bold-200 fc-hui8">
                        每一本书都描绘了瑰丽绚烂的景色，每一本书都是值得深深探究和寻味的宝藏，阅读让我们更加厚重。
                        </div>
                        <a href="wdxs_q/index.html">
                            <div className="detail-btn">
                                查看更多截图
                            </div>
                        </a>
                        <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/wdxs_q/app.rpk" target="_blank">
                            <div className="download-btn">
                                下载应用
                            </div>
                        </a>
                    </div>
                    <div>
                        <img src={require("../assets/images/phone_4.png")} alt="" width="324" height="514"/>
                    </div>
                </div>

                <div className="flex-justify-center box">
                    <div>
                        <img src={require("../assets/images/phone_3.png")} alt="" width="324" height="514"/>
                    </div>
                    <div className="flex-column content-right  flex-justify-center">
                        <img src={require("../assets/images/logo3.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        菁苓阅读（快应用）
                        </div>
                        <div className="fs-20 mt-24 bold-200 ">
                        热门小说免费阅读，东方玄幻、远古神话、古典仙侠、重生娱乐、诙谐搞笑等等应有尽有，你可以随时随地尽情享受阅读的乐趣。
                        </div>
                        
                        <a href="jlyd_q/index.html">
                            <div className=" detail-btn">
                                查看更多截图
                            </div>
                        </a>
                        <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/jlyd_q/app.rpk">
                            <div className="download-btn">
                               下载应用
                            </div>
                        </a>
                    </div>
                </div>

                <div className="flex-justify-center box">
                    <div className="flex-column content  flex-justify-center">
                        <img src={require("../assets/images/logo1.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                            号角追书（快应用）
                        </div>
                        <div className="fs-20 mt-24 bold-200 fc-hui8">
                        全本连载小说尽享阅读，您的追书必备软件。强大书库让您畅游书海，流连忘返。
                        </div>
                        <a href="hjzs_q/index.html">
                            <div className="detail-btn">
                                查看更多截图
                            </div>
                        </a>
                        <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/hjzs_q/app.rpk" target="_blank">
                            <div className="download-btn">
                                下载应用
                            </div>
                        </a>
                    </div>
                    <div>
                        <img src={require("../assets/images/phone_1.png")} alt="" width="324" height="514"/>
                    </div>
               </div>

                <div className="flex-justify-center box">
                    <div>
                        <img src={require("../assets/images/phone_2.png")} alt="" width="324" height="514"/>
                    </div>
                    <div className="flex-column content-right  flex-justify-center">
                        <img src={require("../assets/images/logo2.png")} alt="" width="118" height="118"/>
                        <div className="fs-40 mt-24">
                        免费嗨读小说（快应用）
                        </div>
                        <div className="fs-20 mt-24 bold-200 ">
                        超多免费小说任您畅看，玄幻修仙、都市言情纵情嗨读
                        </div>
                        
                        <a href="mfhdxs_q/index.html">
                            <div className=" detail-btn">
                                查看更多截图
                            </div>
                        </a>
                        <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/mfhdxs_q/app.rpk">
                            <div className="download-btn">
                               下载应用
                            </div>
                        </a>
                    </div>
               </div>
               
               
            
            </div>
        );
    }
export default Product;
