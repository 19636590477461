// @ts-nocheck
import React from 'react'
import '../assets/css/About.styl'
function About() {
        return (
            <div className="about flex-justify-center flex-column">
               <div className="banner flex-items-center flex-justify-center bold">
                <img src={require("../assets/images/about_banner.png")} alt=""/>
               </div>
               <div className="flex-column flex-items-center">
                    <div className="about-title fs-36 mt-36 bold">
                        <div className="tit">
                            关于我们
                        </div>
                    </div>
                    <div className="about-content fs-24 text-center">
                        <div>
                        广西爱尚娱网络科技有限公司
                        </div>
                        <div className="mt-30">
                        联系电话：17800296397（周一至周日，10:00-18:00）
                        </div>
                        <div className="mt-30">
                        联系地址：中国（广西）自由贸易试验区南宁片区庆林路16号天誉花园4号地块5号楼三层3617号
                        </div>
                    </div>
               </div>
            </div>
        );
    }
export default About;